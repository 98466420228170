







































































































import Vue from "vue";

export default Vue.extend({
    name: "SubHeader",
    data() {
        return {               
            gridWidthSlider: {
                color: "red",
                min: "3",
                max: "10",
                step: "0.5",
                ticksLabels: ["3"],
            },
            gridLengthSlider: {
                color: "red",
                min: "3",
                max: "10",
                step: "0.5",
                ticksLabels: ["3"],
            },
            response: null,
            result: null,
            format: "raw",
            locale: undefined, // Browser locale

            valueGridWidthMin: "3",
            valueGridWidthMax: "100",
            valueGridWidthStep: "0.5",
            valueGridWidth: "3",

            valueGridLengthMin: "3",
            valueGridLengthMax: "10",
            valueGridLengthStep: "0.5",
            valueGridLength: "3",

            defaultMenuSector: { text: '' },
            currentMenuSector: { text: '' },

            show: false,                
            sectorInfoImg: require('@/assets/info.png'),
            informationImg: require('@/assets/information-icon.png'),
            tourImg: require('@/assets/tour.svg'),
            subHdrBtnWidth: '36px',
            sectorInfoWidth: '24px'
        };
    },       
    async created() {            
        this.$root.$on('onRecalculate', this.updateDetails);                        
    },
    async mounted() {            
        try {
            await Promise.resolve(
                this.$store.dispatch("datafields/getBayDimensions")
            );
            await Promise.resolve(this.$store.dispatch("datafields/getSectorsList"));

            await Promise.resolve(
                (this.gridWidthSlider.min =
                    this.$store.getters["datafields/getGridWidthMin"])
            );
            await Promise.resolve(
                (this.gridWidthSlider.max =
                    this.$store.getters["datafields/getGridWidthMax"])
            );
            await Promise.resolve(
                (this.gridWidthSlider.step =
                    this.$store.getters["datafields/getGridWidthStep"])
            );
            await Promise.resolve(
                (this.gridWidthSlider.ticksLabels =
                    this.$store.getters["datafields/getGridWidthValues"])
            );

            await Promise.resolve(
                (this.gridLengthSlider.min =
                    this.$store.getters["datafields/getGridLengthMin"])
            );
            await Promise.resolve(
                (this.gridLengthSlider.max =
                    this.$store.getters["datafields/getGridLengthMax"])
            );
            await Promise.resolve(
                (this.gridLengthSlider.step =
                    this.$store.getters["datafields/getGridLengthStep"])
            );
            await Promise.resolve(
                (this.gridLengthSlider.ticksLabels =
                    this.$store.getters["datafields/getGridLengthValues"])
            );
        } catch (error) {
           console.log(error);
        } finally {
            await this.resetSettings();                
        }            
    },        
    computed: {
        subHdrBtnSize() {
            const size = { xs: 'x-small', sm: 'small', md: 'small', lg: 'small', xl: 'small' }[this.$vuetify.breakpoint.name];                
            if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {
                this.subHdrBtnWidth = '20px';
                this.sectorInfoWidth = '18px';
            } else {
                this.subHdrBtnWidth = '36px';
                this.sectorInfoWidth = '24px';
            }
            return size ? { [size]: true } : {}
        },
        infoLink() {
            return `${this.$timberURL}/user-guide`;
        },
        gridWidthMin() {                
            return this.$store.getters["datafields/getGridWidthMin"];
        },
        gridWidthMax() {                
            return this.$store.getters["datafields/getGridWidthMax"];
        },
        gridWidthStep() {                
            return this.$store.getters["datafields/getGridWidthStep"];
        },
        gridLengthMin() {                
            return this.$store.getters["datafields/getGridLengthMin"];
        },
        gridLengthMax() {                
            return this.$store.getters["datafields/getGridLengthMax"];
        },
        gridLengthStep() {                
            return this.$store.getters["datafields/getGridLengthStep"];
        },
        sectors() {                
            return this.$store.getters["datafields/getSectors"];
        }
    },
    methods: {
        startTour() {
            this.$tours["timberTour"].start();    
        },
        async resetSettings() {                
            try {
                await Promise.resolve(this.defaultMenuSector.text = this.$store.getters["datafields/getActiveSectorName"]);
                await Promise.resolve(this.currentMenuSector = this.defaultMenuSector)

                // load data for controls
                await Promise.resolve(
                    this.$store.dispatch("materials/setBayX", this.valueGridLengthMin)
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setBayY", this.valueGridWidthMin)
                );
                await Promise.resolve(
                    this.$store.dispatch(
                        "materials/setSector",
                        this.defaultMenuSector.text
                    )
                );
                await Promise.resolve((this.valueGridWidth = this.valueGridLengthMin));
                await Promise.resolve((this.valueGridLength = this.valueGridWidthMin));
                await Promise.resolve(
                    (this.currentMenuSector = this.defaultMenuSector)
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/setBestTimberMaterial")
                );
                
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialNames", ["Timber", "text", "textVal"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialNames", ["Concrete", "text", "textVal"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialNames", ["Steel", "text", "textVal"])
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["BayUnitWeight"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", [
                        "OverallStructuralDepth",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Cost"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Carbon"])
                );

                // load material values
                await Promise.resolve(
                    this.$store.dispatch("materials/setBestAsCurrentTimber")
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setIsAutoSelectEnabled", true)
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["materials.mtl"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/getMaterialDetails", [
                        "Timber",
                        "",
                        this.valueGridWidth,
                        this.valueGridLength,
                        this.currentMenuSector.text,
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Timber"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/getMaterialDetails", [
                        "Concrete",
                        "",
                        this.valueGridWidth,
                        this.valueGridLength,
                        this.currentMenuSector.text,
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Concrete"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/getMaterialDetails", [
                        "Steel",
                        "",
                        this.valueGridWidth,
                        this.valueGridLength,
                        this.currentMenuSector.text,
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Steel"])
                );
                
                // set chart data
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Timber",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Concrete",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Steel",
                    ])
                );
                // set option summary data
                await Promise.resolve(
                    this.$store.dispatch("materials/setOptionSummary")
                );
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Timber", "cardTimber3D"));
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Concrete", "cardConcrete3D"));
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Steel", "cardSteel3D"));
            } catch (error) {
                console.log(error);
            } finally {                    
                try {
                   this.$root.$emit("setTimberBestActive");
                    this.$root.$emit("updateAssumptions", "Timber");
                    this.$root.$emit("updateAssumptions", "Concrete");
                    this.$root.$emit("updateAssumptions", "Steel");
                    
                } catch (error) {
                    console.log(error);
                } finally {
                    this.$root.$emit("obtainMaterialObject", "Timber", "cardTimber3D", 'isoView');
                    this.$root.$emit("obtainMaterialObject", "Concrete", "cardConcrete3D", 'isoView');
                    this.$root.$emit("obtainMaterialObject", "Steel", "cardSteel3D", 'isoView');
                    this.$root.$emit("refreshChart", "Timber");
                    this.$root.$emit("refreshChart", "Concrete");
                    this.$root.$emit("refreshChart", "Steel");
                    this.$root.$emit("refreshOptionSummary");
                }
            }
        },
        valueChanged: async function (valueChange, propChange) {
            await Promise.resolve(
                this.$root.$emit("refreshValues", valueChange, propChange)
            );
        },
        async sectorChanged(value) {
            try {
                await Promise.resolve(
                    this.$store.dispatch("materials/setSector", [value])
                );
            } catch (error) {                    
            } finally {
                await Promise.resolve(this.updateDetails());
            }                
        },
        async bayXChanged(value) {
            try {
                await Promise.resolve(
                    this.$store.dispatch("materials/setBayX", [value])
                );
            } catch (error) {
            } finally {
                await Promise.resolve(this.updateDetails());
            }                
        },
        async bayYChanged(value) {
            try {
                await Promise.resolve(
                    this.$store.dispatch("materials/setBayY", [value])
                );
            } catch (error) {
            } finally {
                await Promise.resolve(this.updateDetails());
            }               
        },
        async updateDetails() {
            try {
                // load data for controls
                await Promise.resolve(
                    this.$store.dispatch("materials/setBestTimberMaterial")
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialNames", ["Concrete", "text", "textVal"])
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["BayUnitWeight"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", [
                        "OverallStructuralDepth",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Cost"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Carbon"])
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/setBestAsCurrentTimber")
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["materials.mtl"])
                );
                // load material values
                await Promise.resolve(
                    this.$store.dispatch("materials/updateMaterialDetails", ["Timber"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Timber"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/updateMaterialDetails", ["Concrete"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Concrete"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/updateMaterialDetails", ["Steel"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Steel"])
                );

                // set chart data
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Timber",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Concrete",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setChartDataByMaterialType", [
                        "Steel",
                    ])
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/setOptionSummary")
                );
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Timber", "cardTimber3D"));
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Concrete", "cardConcrete3D"));
                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Steel", "cardSteel3D"));
            } catch (error) {
                console.log(error);
            } finally {
                try {
                    this.$root.$emit("setTimberBestActive");
                    this.$root.$emit("updateAssumptions", "Timber");
                    this.$root.$emit("updateAssumptions", "Concrete");
                    this.$root.$emit("updateAssumptions", "Steel");
                } finally {
                    this.$root.$emit("obtainMaterialObject", "Timber", "cardTimber3D", '');
                    this.$root.$emit("obtainMaterialObject", "Concrete", "cardConcrete3D", '');
                    this.$root.$emit("obtainMaterialObject", "Steel", "cardSteel3D", '');
                    this.$root.$emit("refreshChart", "Timber");
                    this.$root.$emit("refreshChart", "Concrete");
                    this.$root.$emit("refreshChart", "Steel");
                    this.$root.$emit("refreshOptionSummary");
                }
            }
        },
    },
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "subheader-container pb-0" },
    [
      _c(
        "v-row",
        { staticClass: "subheader-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "div",
            { staticClass: "col row v-step-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c("v-slider", {
                    staticClass: "pad-right",
                    attrs: {
                      label: "grid width",
                      "tick-size": "1",
                      ticks: "always",
                      min: _vm.gridWidthSlider.min,
                      max: _vm.gridWidthSlider.max,
                      step: _vm.gridWidthSlider.step,
                      "track-color": "#6DA8B6",
                      color: "#6DA8B6",
                      "thumb-color": "#696A6C",
                      "thumb-label": "always",
                      "hide-details": "auto"
                    },
                    on: {
                      change: function($event) {
                        return _vm.bayYChanged(_vm.valueGridWidth)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "thumb-label",
                        fn: function(ref) {
                          var value = ref.value
                          return [_vm._v(" " + _vm._s(value) + "m ")]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.valueGridWidth,
                      callback: function($$v) {
                        _vm.valueGridWidth = $$v
                      },
                      expression: "valueGridWidth"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c("v-slider", {
                    staticClass: "pad-right",
                    attrs: {
                      label: "grid length",
                      "tick-size": "1",
                      ticks: "always",
                      min: _vm.gridLengthSlider.min,
                      max: _vm.gridLengthSlider.max,
                      step: _vm.gridLengthSlider.step,
                      "track-color": "#6DA8B6",
                      color: "#6DA8B6",
                      "thumb-color": "#696A6C",
                      "thumb-label": "always",
                      "hide-details": "auto"
                    },
                    on: {
                      change: function($event) {
                        return _vm.bayXChanged(_vm.valueGridLength)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "thumb-label",
                        fn: function(ref) {
                          var value = ref.value
                          return [_vm._v(" " + _vm._s(value) + "m ")]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.valueGridLength,
                      callback: function($$v) {
                        _vm.valueGridLength = $$v
                      },
                      expression: "valueGridLength"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "3" } },
            [
              _c(
                "v-layout",
                { staticClass: "v-step-5", attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs2: "" } },
                    [
                      _c(
                        "v-subheader",
                        { staticClass: "padlabel-top" },
                        [_c("v-label", [_vm._v("sector")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "sector-select", attrs: { xs9: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "",
                          id: "sector",
                          items: _vm.sectors,
                          value: _vm.currentMenuSector,
                          dense: "",
                          attach: "",
                          elevation: "2",
                          "item-color": "#EFEB32",
                          color: "#696A6C",
                          "hide-details": ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.sectorChanged(_vm.currentMenuSector)
                          }
                        },
                        model: {
                          value: _vm.currentMenuSector,
                          callback: function($$v) {
                            _vm.currentMenuSector = $$v
                          },
                          expression: "currentMenuSector"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs1: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "sector_info-btn",
                          attrs: {
                            elevation: "2",
                            icon: "",
                            "x-small": "",
                            href: "" + _vm.infoLink,
                            target: "_blank"
                          }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.sectorInfoImg,
                              width: _vm.sectorInfoWidth
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "3" } },
            [
              _c(
                "v-layout",
                { staticClass: "pt-2", attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.startTour()
                                            }
                                          }
                                        },
                                        "v-btn",
                                        [attrs, _vm.subHdrBtnSize],
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: _vm.tourImg,
                                          width: _vm.subHdrBtnWidth
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Application Guide Tour")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            elevation: "2",
                                            dark: "",
                                            icon: "",
                                            color: "primary",
                                            href: "" + _vm.infoLink,
                                            target: "_blank"
                                          }
                                        },
                                        "v-btn",
                                        [attrs, _vm.subHdrBtnSize],
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-img", {
                                        staticClass: "v-step-11",
                                        attrs: {
                                          src: _vm.informationImg,
                                          width: _vm.subHdrBtnWidth
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("User Guide")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }